<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
    >
        <w-data-table
            :headers="headers"
            :items="itemsComputed"
            item-key="uuid"
            searchable
            :filter-by="['title']"
            @click:row="item => $router.push({name: 'groups.details', params: {uuid: item.uuid}})"
        >
            <!-- actions -->
            <template #item.actions>
                <w-icon-next
                    color="primary"
                />
            </template>
        </w-data-table>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import { getGroupsCollection } from '@apps/school/api/groups'
export default {
    name: 'Groups',
    components: { ContentWrapper },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Groups')
                }
            ]
        },
        headers() {
            return [
                {
                    text: this.$trans('Group'),
                    value: 'title'
                },
                {
                    text: this.$trans('Level'),
                    value: 'levelTitle'
                },
                {
                    text: this.$trans('School'),
                    value: 'schoolTitle'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        },
        itemsComputed() {
            return this.items.map(o => {
                o.levelTitle = o.level.title
                o.schoolTitle = o.level.school.title

                return o
            })
        }
    },
    data: () => ({
        items: []
    }),
    mounted() {
        this.fetchGroups()
    },
    methods: {
        async fetchGroups() {
            this.$lprogress.begin()

            try {
                const payload = {
                    teacher: this.$auth.member.uuid,
                    with: 'level.school'
                }
                const response = await getGroupsCollection(payload)
                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
